/**
 * Корректный формат даты (регулярка на формат SQL_DATE_FORMAT)
 */
export const REGEXP_DATE_CORRECT_FORMAT = /([0-9]{4})-([0-9]{2})-([0-9]{2})$/g;

/**
 * Формат даты по умолчанию
 * Пример: 16.01.2023
 */
export const DATE_FORMAT = 'DD.MM.YYYY';

/**
 * Формат даты под SQL для бэкенда
 * Пример: 2023-01-16
 */
export const SQL_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Формат даты-времени под SQL для бэкенда
 * Пример: 2023-01-16T09:02:45
 */
export const SQL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

/**
 * Строка короткого формата даты
 */
export const DATE_FORMAT_SHORT = 'D MMM';

/**
 * Строка короткого формата даты
 */
export const DATE_FORMAT_SHORT_PLUSE = 'D MMMM';

/**
 * Дата и время без секунд
 */
export const DATE_TIME_SHORT_FORMAT = 'DD.MM.YYYY HH:mm';

/**
 * Дата и время без секунд с запятой между датой и временем
 */
export const DATE_TIME_SHORT_FORMAT_WITH_COMMA = 'DD.MM.YY, HH:mm';

/**
 * Время без милисекунд
 */
export const TIME_FORMAT = 'HH:mm';
