import { defu } from 'defu';
import { HTTP_RESPONSE_CODE } from '~/constants/api.const';

import type { UseFetchOptions } from 'nuxt/app';
import type { ZodSchema } from 'zod';

/**
 * Тип параметров функции обращения к бэкенду
 */
export type UseApiOptions<T> = UseFetchOptions<T> & {
  model?: ZodSchema;
};

/**
 * Подмешиваем Authorization заголовок в запросы из Cookie для авторизации при SSR
 */
function _prepareHeaders() {
  const headers = useRequestHeaders(['cookie', 'x-mocks', 'x-mocks-partial', 'Authorization']);
  let token;
  if (import.meta.client) {
    const { accessToken } = useAuth();
    if (accessToken) {
      token = accessToken;
    }
  } else if (import.meta.server) {
    const accessToken = getCookieValueFromCookie(headers.cookie, 'accessToken');
    if (accessToken) {
      token = accessToken;
    }
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

/**
 * Общая функция запроса данных с бэкенда в API слое проекта
 */
export function useApi<T>(url: string | (() => string), options: UseApiOptions<T> = {}) {
  const { public: publicConfig } = useRuntimeConfig();
  const { refreshToken } = useAuth();

  const headers = _prepareHeaders();

  const defaults: UseFetchOptions<T> = {
    baseURL: publicConfig.apiBaseUrl,
    headers,
    /**
     * Хук на обработку каждого ответа
     */
    async onResponse(ctx) {
      // Выполнение редиректа, если бэк ответил редиректом
      if (ctx.response.status === 302) {
        await navigateTo(ctx.response.url);
        return;
      }

      if (options.model) {
        const parsed = options.model.safeParse(ctx.response._data.data);

        if (parsed.success) {
          ctx.response._data = parsed.data;
        } else if (ctx.response.status === HTTP_RESPONSE_CODE.SERVER_ERROR) {
          console.error('Server error', url, parsed.error);
          return;
        } else if (ctx.response.status !== HTTP_RESPONSE_CODE.TOKEN_EXPIRED) {
          const auth = useAuth();
          await auth.refreshToken();
          await refreshNuxtData();
        } else if (ctx.response.status !== HTTP_RESPONSE_CODE.UNAUTHORIZED) {
          console.error({
            url,
            error: parsed.error,
            model: options.model,
            data: ctx.response._data,
          });
        }
      }

      // Сохраняем возможность использовать этот хук позже
      if (typeof options.onResponse === 'function') {
        await options.onResponse(ctx);
      }
    },
    /**
     * Хук на ошибку ответа
     */
    async onResponseError(ctx) {
      // проверка авторизации и обновление протухшего токена
      if (
        ctx.response.status === HTTP_RESPONSE_CODE.TOKEN_EXPIRED ||
        // TODO: fix after status code updates
        ctx.response.status === HTTP_RESPONSE_CODE.UNAUTHORIZED
      ) {
        await refreshToken();
        throw new Error('refresh');
      }
      // Сохраняем возможность использовать этот хук позже
      if (typeof options.onResponseError === 'function') {
        options.onResponseError(ctx);
      }
    },
    onRequest(ctx) {
      if (typeof options?.onRequest === 'function') {
        options.onRequest(ctx);
      }
    },
  };

  // Объединение параметров. Unjs/defu — внутренняя зависимость Nuxt
  const params = defu(options, defaults);

  return useFetch(url, params);
}


export function useWrappedFetch(url: string, options?: any) {
  const { public: publicConfig } = useRuntimeConfig();

  const headers = _prepareHeaders();

  return $fetch(url, {
    baseURL: publicConfig.apiBaseUrl,
    headers,
    ...options,
  });
}
