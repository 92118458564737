/**
 * Добавляет версию в api endpoint
 *
 * @param endpoint - полный URL запроса
 * @param apiVersion - версия api
 * @param routeVersion - принудительно сделать URL с нужной версией для конкретного запроса
 */
export function versioned(endpoint: string, apiVersion?: number, routeVersion?: number) {
  const withVersion = routeVersion ?? apiVersion;
  // TODO: refactor default version to get it here
  return `/v${withVersion}${endpoint}`;
}

/**
 * Записываем cookie
 *
 * @param name имя куки
 * @param value строка со значением
 * @param days expires в днях
 */
export function setCookie(name: string, value: string, days?: number) {
  if (import.meta.server) return;
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

/**
 * Извлекает значение куки с указанным именем из заголовков запроса.
 *
 * @param cookie - Строка, содержащая куки.
 * @param cookieName - Имя куки, значение которого нужно получить.
 * @returns Значение куки, если она найдена, иначе `null`.
 */
export function getCookieValueFromCookie(cookies?: string, cookieName?: string): string | null {
  if (!cookies) return null;

  const match = cookies.split(';').find((cookie) => cookie.trim().startsWith(`${cookieName}=`));
  return match ? match.split('=')[1].trim() : null;
}
