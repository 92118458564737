import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import isBetween from 'dayjs/plugin/isBetween.js';
import timezone from 'dayjs/plugin/timezone.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import 'dayjs/locale/ru';
import { DEFAULT_LOCALE } from '../constants/common.const';
import { SQL_DATE_FORMAT, REGEXP_DATE_CORRECT_FORMAT } from '../constants/display.const';
import type { Dayjs, ManipulateType, OptionType, OpUnitType, QUnitType } from 'dayjs';

/**
 * Добавляет API .utc, .local, .isUTC для возможности разбора
 * или отображения данных в формате UTC
 */
dayjs.extend(utc);

/**
 * CustomParseFormat расширяет конструктор dayjs() для поддержки
 * пользовательских форматов входных строк.
 */
dayjs.extend(customParseFormat);

/**
 * Добавляет API .from, .to, .fromNow, .toNow для форматирования строкового
 * представления даты в формате относительного времени (например, 3 часа назад)
 */
dayjs.extend(relativeTime);

/**
 * Добавляет API .isBetween(), которое возвращает булево значение,
 * указывающее на то, находится ли дата в промежутке между двумя другими
 */
dayjs.extend(isBetween);

/**
 * Добавляет API dayjs.tz, .tz, .tz.guess, .tz.setDefault для
 * парсинга или отображения временных зон
 */
dayjs.extend(timezone);

/**
 * Расширяет API dayjs().format для возможности предоставления
 * локализованных форматов, например dayjs().format('LL')
 */
dayjs.extend(localizedFormat);

// Установка локали
dayjs.locale(DEFAULT_LOCALE);

/**
 * Формат даты, который принимает утилита dateTime
 */
type DateFormat = string | number | Date | Dayjs | null;

/**
 * Тип единиц времени, которые принимает утилита dateTime
 */
type DateUnit = QUnitType | OpUnitType;

/**
 * Тип объекта для работы с датами
 */
export type DateTime = Dayjs;

/**
 * Утилита работы с датами и временем
 */
export const dateTime = {
  /**
   * Аналогично простому вызову dayjs()
   *
   * @param date Дата
   * @param format Строка с форматом данных
   * @param strict Строгое соответствие формату
   * @returns Экземпляр Dayjs
   */
  parse(date?: DateFormat, format?: OptionType, strict?: boolean): Dayjs {
    return dayjs(date, format, strict);
  },

  /**
   * Получить дату в определенном формате
   *
   * @param date Дата
   * @param template Шаблон даты
   * @returns Отформатированная строка
   * @see https://day.js.org/docs/ru/parse/string-format
   */
  format(date?: DateFormat, template: string = SQL_DATE_FORMAT) {
    return dayjs(date).format(template);
  },

  /**
   * Разница между двумя дато-временными значениями в указанной единице
   *
   * @param date1 Начальная дата
   * @param date2 Конечная дата. Если не передано, то будет считаться текущей датой
   * @param unit Единица измерения, например 'minute', 'day', 'year'
   * @param float Отображать ли полученный результат в виде числа с плавающей точкой
   * @returns Разница между двумя дато-временными значениями
   * @see https://day.js.org/docs/ru/display/difference
   */
  diff(date1: DateFormat, date2?: DateFormat, unit?: DateUnit, float?: boolean) {
    return dayjs(date1).diff(date2, unit, float);
  },

  /**
   * Возвращает клонированный Day.js объект с определенным количеством добавленного времени.
   *
   * @param value Количество добавляемых единиц времени
   * @param unit Единица измерения, например 'minute', 'day', 'year'
   * @returns Экземпляр Dayjs
   * @see https://day.js.org/docs/ru/manipulate/add
   */
  add(value: number, unit?: ManipulateType) {
    return dayjs().add(value, unit);
  },

  /**
   * Возвращает клонированный Day.js объект с определенным количеством вычтенного времени.
   *
   * @param value Количество вычетаемых единиц времени
   * @param unit Единица измерения, например 'minute', 'day', 'year'
   * @returns Экземпляр Dayjs
   * @see https://day.js.org/docs/ru/manipulate/subtract
   */
  subtract(value: number, unit?: ManipulateType) {
    return dayjs().subtract(value, unit);
  },

  /**
   * Получить период дат в формате 'dateFrom - dateTo'
   *
   * @param dateTo Дата конца периода
   * @param periodDays Сколько дней в периоде
   * @param format
   * @throws {Error}
   * @see {@link ./dateTime.test.ts}
   */
  getRange(dateTo: string | number | Date, periodDays: number, format = 'LL') {
    if (periodDays <= 0) {
      throw new Error('Ошибка: В getRange параметр periodDays меньше или равен нулю');
    }

    // Дата dateToString
    const dateToObject = new Date(dateTo);
    const dateToString = dayjs(dateToObject).format(format);

    // Дата dateFromString
    const dateFromObject = new Date(dateToObject.setDate(dateToObject.getDate() - periodDays + 1));
    const dateFromString = dayjs(dateFromObject).format(format);

    return `${dateFromString} — ${dateToString}`;
  },

  /**
   * Является ли указанная дата выходным днем
   *
   * @param date Дата. Если не определено, то будет проверяться текущая дата
   */
  isWeekend(date?: DateFormat) {
    const dayName = dayjs(date).format('dd');
    return ['сб', 'вс'].includes(dayName);
  },

  /**
   * Текущая дата
   *
   * @returns Экземпляр Dayjs
   */
  today() {
    return dayjs();
  },

  /**
   * Вчера
   *
   * @returns Экземпляр Dayjs
   */
  yesterday() {
    return this.today().subtract(1, 'day');
  },

  /**
   * Проверяет корректность даты
   *
   * @param dateString Дата в формате строки
   * @param regexpDateCorrectFormat Регулярное выражение формата даты
   * @returns
   */
  isCorrectDate(
    dateString: string,
    regexpDateCorrectFormat: RegExp = REGEXP_DATE_CORRECT_FORMAT,
  ): boolean {
    const isValid = dateString.match(regexpDateCorrectFormat) !== null;
    const isCorrect = !Number.isNaN(new Date(dateString).getTime());

    return isValid && isCorrect;
  },

  /**
   * Конвертация текущей даты в новый часовой пояс
   *
   * @param dateTime Дата
   * @param newTimeZone Новый часовой пояс
   * @return Новая дата с пересчитанным часовым поясом
   */
  toTimeZone(date: Date, newTimeZone: number): Date {
    if (!(date instanceof Date)) {
      throw new TypeError("Date type for 'date' is expected");
    }

    if (typeof newTimeZone !== 'number') {
      throw new TypeError('Timezone value must be a number');
    }

    const mskDate = new Date(date);

    const yourTimezone = mskDate.getTimezoneOffset();
    if (yourTimezone > 0) {
      mskDate.setMinutes(mskDate.getMinutes() - yourTimezone);
    } else {
      mskDate.setMinutes(mskDate.getMinutes() + yourTimezone);
    }
    mskDate.setHours(mskDate.getHours() + newTimeZone);

    return mskDate;
  },

  /**
   * Ставит время в 23:59:59
   * @param day
   */
  setToTheEndOfDay: (one: Dayjs) => one.hour(23).minute(59).second(59).millisecond(999),

  /**
   * Проверяет переданный timestamp в будущем или нет
   *
   * @param unixTimestampInSeconds число в секундах unixtimestamp
   */
  isInFuture(unixTimestampInSeconds: number) {
    const tokenExpiredAt = dayjs.unix(unixTimestampInSeconds);
    const now = this.today();
    return now.isBefore(tokenExpiredAt, 'seconds');
  },
};
