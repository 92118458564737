import { API_DEFAULT_VERSION } from '../api.const';
import { versioned } from '~/utils/api.utils';

export const useAuthEndpoints = (version?: number) => {
  const withVersion = version && Number.isInteger(version) ? version : API_DEFAULT_VERSION;

  return {
    /**
     * Маршрут для запроса loginToken
     *
     * @method POST
     */
    authPrepare: () => versioned('/auth/prepare', withVersion),

    /**
     * Авторизация по loginToken'у
     *
     * @method POST
     */
    authByToken: () => versioned('/auth/login', withVersion),

    /**
     * Роут для обновление токена
     *
     * @method POST
     */
    authRefresh: () => versioned('/auth/refresh_tokens', withVersion),
  };
};
