/**
 * HTTP коды ответов
 */
export enum HTTP_RESPONSE_CODE {
  OK = 200,
  /**
   * Не авторизован
   */
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  /**
   * Токен авторизации протух, его надо обновить
   */
  TOKEN_EXPIRED = 498,
  SERVER_ERROR = 500,
}
