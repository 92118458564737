interface AuthState {
  loginToken: string;
  botName: string;
  expiredAt: number;
  jwt: {
    accessToken: string;
    expiredAt: number;
    refreshToken: string;
  };
}

/**
 * Управление авторизацией пользователя
 */
export const useAuth = defineStore(
  'auth',
  () => {
    const state = ref<AuthState>({
      loginToken: '',
      botName: '',
      expiredAt: 0,
      jwt: {
        accessToken: '',
        expiredAt: 0,
        refreshToken: '',
      },
    });

    /**
     * Store для внутренних нужд
     */
    const _stuff = ref({
      /**
       * Происходит рефреш accessToken'а
       */
      isRefreshing: false,
    });

    function persistToCookie() {
      const { accessToken, expiredAt } = state.value.jwt;
      setCookie('accessToken', accessToken);
      setCookie('expiredAt', expiredAt.toString());
    }

    /**
     * Очищает токен от префикса login_
     *
     * @param token
     */
    function _clearStringFromPrefix(srt: string, prefix: string = 'login_') {
      if (srt.startsWith(prefix)) {
        return srt.slice(prefix.length);
      }
      return srt;
    }

    /**
     * Сброс состояния на исходное
     */
    function _resetState() {
      _clearLoginToken();
      state.value.botName = '';
      state.value.jwt.accessToken = '';
      state.value.jwt.expiredAt = 0;
      state.value.jwt.refreshToken = '';
      persistToCookie();
    }

    /**
     * Очищаем loginToken, чтобы в дальнейшем его можно было перезапросить
     */
    function _clearLoginToken() {
      state.value.loginToken = '';
      state.value.expiredAt = 0;
    }

    /**
     * Выход из приложения
     */
    function logout() {
      _resetState();
    }

    /**
     * Получить loginToken, если нет активного в storage
     */
    async function fetchLoginTokenIfRequired() {
      if (dateTime.isInFuture(state.value.expiredAt)) return;
      const { data: response, execute } = useApiAuthPrepare();
      await execute();
      const data = response.value?.data;
      if (!data?.loginToken) {
        throw new Error('Error in fetching loginToken');
      }
      state.value.loginToken = data.loginToken;
      state.value.botName = data.botName;
      state.value.expiredAt = data.expiredAt;
    }

    /**
     * Попытка авторизоваться по loginToken
     */
    async function tryToLoginByToken() {
      if (!state.value.loginToken) {
        throw new Error('Undefined loginToken');
      }
      const rawToken = _clearStringFromPrefix(state.value.loginToken);
      try {
        const { data, execute } = useApiAuthByLoginToken(rawToken, {
          onResponseError(ctx) {
            if (ctx.response.status === HTTP_RESPONSE_CODE.NOT_ACCEPTABLE) {
              _clearLoginToken();
            }
            if (ctx.response.status === HTTP_RESPONSE_CODE.TOKEN_EXPIRED) {
              refreshToken();
            }
            return false;
          },
        });
        await execute();
        const jwt = data.value?.data;
        // Если токен не подтверждён в ТГ, то авторизация невозможна
        if (!jwt) return;
        // error._object[error._key].statusCode
        // Если произошла какая-то ошибка и бэк не ответил
        if (jwt.status || jwt === null) {
          throw new Error('Undefined jwt data');
        }
        _clearLoginToken();
        state.value.jwt.accessToken = jwt.accessToken;
        state.value.jwt.expiredAt = jwt.expiredAt;
        state.value.jwt.refreshToken = jwt.refreshToken;
        persistToCookie();
      } catch (e) {
        logDevMessage(e);
      }
    }

    /**
     * authToken пора рефрешить
     */
    const isRequiredToRefresh = computed(() => !dateTime.isInFuture(state.value.jwt.expiredAt));

    // TODO: отладить работу апдейта токенов
    async function refreshToken() {
      if (!state.value.jwt.refreshToken) return;
      if (_stuff.value.isRefreshing) return;
      _stuff.value.isRefreshing = true;
      const { data, execute } = useApiAuthRefresh(state.value.jwt.refreshToken);
      await execute();
      const jwt = data.value?.data;
      if (!jwt && (!jwt?.accessToken || !jwt?.expiredAt || !jwt?.refreshToken)) {
        throw new Error('Undefined jwt data after refreshing token');
      }
      state.value.jwt.accessToken = jwt.accessToken;
      state.value.jwt.expiredAt = jwt.expiredAt;
      state.value.jwt.refreshToken = jwt.refreshToken;
      persistToCookie();
      _stuff.value.isRefreshing = false;
    }

    /**
     * Залогинен ли пользователь
     */
    const isLoggedIn = computed(() => !!state.value.jwt.accessToken);

    /**
     * Проверка не истёк ли токен
     */
    const isLoginTokenAlive = computed(() => dateTime.isInFuture(state.value.expiredAt));

    /**
     * Авторизация через loginToken
     *
     * Получение loginToken, если это гость и первый заход на страницу
     * Авторизация по loginToken, если это вернувшийся пользователь
     */
    async function optimisticAuth() {
      infoDevMessage('auth.optimisticAuth');
      if (isLoggedIn.value) return true;
      if (!isLoginTokenAlive.value) {
        await fetchLoginTokenIfRequired();
      } else {
        await tryToLoginByToken();
        // TODO: если прошло много времени - показать уведомление, что нужно повторное подтверждение
        if (!state.value.loginToken) {
          //
        }
      }
      return false;
    }

    async function optimisticAuthIfNotYet() {
      infoDevMessage('auth.optimisticAuthIfNotYet');
      if (isLoggedIn.value) return;
      if (state.value.loginToken && isLoginTokenAlive.value) {
        await tryToLoginByToken();
      }
    }

    return {
      state,

      isLoggedIn,

      /**
       * Токен для логина
       */
      loginToken: computed(() => state.value.loginToken),
      /**
       * Токен для доступа
       */
      accessToken: computed(() => state.value.jwt.accessToken),
      /**
       * Имя бота для авторизации
       */
      botName: computed(() => state.value.botName),
      isLoginTokenAlive,

      fetchLoginTokenIfRequired,
      tryToLoginByToken,
      refreshToken,
      logout,
      optimisticAuth,
      optimisticAuthIfNotYet,
      isRequiredToRefresh,
    };
  },
  {
    persist: true,
  },
);
